import React from "react"
import { Link } from "gatsby"

const Category = ({ children, slug, className, ...props }) => (
  <div
    className={`font-mono text-sm font-medium border-b-2 border-red inline-block ${className}`}
    {...props}
  >
    <Link to={`/category/${slug}`}>{children}</Link>
  </div>
)

export default Category
